<template>
	<div class="wifi">
		<div class="box">
			<el-form ref="basicParams" label-width="200px" label-position="left">
			  <!--  -->
			  <el-form-item label="一键WIFI">
			    <el-input v-model="wifiData.WiFiAppId" size="small" placeholder="请输入小程序APPID"></el-input>
			  	<p>*小程序APPID</p>
			  	<el-input v-model="wifiData.WiFiSSID" size="small" placeholder="请输入小程序页面路径"></el-input>
			  	<p>*WIFI名称</p>
					<el-input v-model="wifiData.WiFiPassword" size="small" placeholder="请输入小程序页面路径"></el-input>
					<p>*WIFI的密码</p>
			  </el-form-item>
				<!--  -->
				<el-form-item>
					<el-button type="primary" @click="onSubmit" size="small" >保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import api from '../../../../api/setting.js';
	export default {
		data() {
			return {
				wifiData:{}
			}
		},
		mounted() {
			this.queryData();
		},
		methods:{
			async queryData(){
				try{
					let { data } = await api.wxmpWifi('init_data');
					this.wifiData = data;
				}catch(err){
					console.log(err)
				}
			},
			async onSubmit(){
				try{
					let { data } = await api.wxmp_wifi(this.wifiData,'save_data');
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.queryData();
				}catch(err){
					console.log(err)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
.box{
	display: flex;
	align-items: center;
	flex-direction: column;
	.el-form{
		width: 720px;
		margin-top: 20px;
		margin: 0 auto;
		.el-input{
			width: 100%;
		}
	}
	p,span{
		color: #8492a6;
		line-height: 25px;
	}
}
</style>
